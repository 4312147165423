import React, {useEffect, useRef} from "react";
import Headline from "./Headline";
import {Link} from "gatsby";
import MyPortableText from "./MyPortableText";
import {headlineAnimation} from "../Animation/animation";

const KontaktItem = ({el, index, length}) => {

    const kontaktLineRef = useRef();

    useEffect(() => {
        //headlineAnimation(kontaktLineRef.current);
    })

    return (
        <div className="kontakt__item">
            <p className="large museo title">{el.title}</p>
            <div className={"address minion"}>
                <MyPortableText value={el._rawDaten}></MyPortableText>
            </div>
            <a href={"mailto:" + el.email} className={"hover-moveup"}>
                <p className="underline minion email">{el.email}</p>
            </a>
            {index !== length -1 ? <div className="separator half" ref={kontaktLineRef}></div> : <></>}
        </div>
    )
}


export default KontaktItem

import React from "react";
import Headline from "./Headline";
import MyPortableText from "./MyPortableText";


const About = ({text}) => {

    return (
        <div className={"about__outer container"}>
            <div className="about__inner">
                <Headline>Über uns</Headline>
                <div className="minion large center">
                    <MyPortableText value={text}></MyPortableText>
                </div>
            </div>
        </div>
    )
}


export default About

import * as React from "react"
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import Books from "../components/Books";
import About from "../components/About";
import Kontakte from "../components/Kontakte";
import SEO from "../components/SEO";

export const query = graphql`
{
  allSanityStartseite {
    edges {
      node {
        kontakt {
          _rawDaten
          email
          title
        }
        buecher {
       
          Authors {
            name
            _rawText
          }
          layout
          ausgaben {
            kauflink
            isbn
            _rawInfo
          }
          cover {
            asset {
              gatsbyImageData
            }
          }
          downloads {
            title
            file {
              asset {
                url
              }
            }
          }
          pressestimmen {
            name
            _rawText
          }
          seitenbilder {
            asset {
              gatsbyImageData
            }
          }
          subtitle
          title
          _rawLangtext
          _rawKurztext
          slug{
          current}
        }
        _rawAbout
      }
    }
  }
  allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
  }
  }
  }
`

const IndexPage = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;

  return (
    <Layout animated={true} agbs={data.allSanityAgbs.edges[0].node.agbfile.asset.url}>
        <SEO />
        <div className={"start-placeholder scroll-child"}></div>
        <Books books={page.buecher}></Books>
        {page._rawAbout ? <About text={page._rawAbout}></About> : <></>}
       <Kontakte kontakte={page.kontakt}></Kontakte>
    </Layout>
  )
}

export default IndexPage

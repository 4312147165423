import React from "react";
import Headline from "./Headline";
import {Link} from "gatsby";
import MyPortableText from "./MyPortableText";
import KontaktItem from "./KontaktItem";

const Kontakte = ({kontakte}) => {

    let i = 0;

    return (
        <div className={"kontakte__outer container"} id={"kontakt"}>
            <div className="kontakte__inner">
                <Headline>Kontakt</Headline>
                <div className="kontakte__container desktop">
                    <div className="kontakt__row">
                        {kontakte.map((el) => {
                            return (
                                <div className="kontakt__item">
                                    <p className="large museo title">{el.title}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="kontakt__row">
                        {kontakte.map((el, key) => {
                            return (
                                <div className={key === kontakte.length - 1 ? 'kontakt__item address__container last' : 'kontakt__item address__container'}>
                                    <div className={"address minion small"}>
                                        <MyPortableText value={el._rawDaten}></MyPortableText>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="kontakt__row">

                        {kontakte.map((el) => {
                            return (
                                <div className="kontakt__item">
                                    <a href={"mailto:" + el.email} className={"hover-moveup"}><p className="underline minion email">{el.email}</p>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="kontakte__container mobile">
                    <div className="kontakt__row">
                        {kontakte.map((el, index) => {
                          return (<KontaktItem el={el} index={index} length={kontakte.length}></KontaktItem>);
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Kontakte

import React, {useEffect, useRef} from "react";
import Headline from "./Headline";
import Book from "./Book";
import {headlineAnimation} from "../Animation/animation";


const Books = ({books}) => {

    const rand = Math.floor(Math.random() * 4);

    return (
        <div className={"books__outer container scroll-child"} id={"buecher"}>
            <div className="books__inner">
                <Headline title={"books"}>Bücher</Headline>
                <div className="booklist">
                    {books.map((el, key) => {
                        console.log(el);
                        return <Book index={key} start={rand} slug={el.slug.current} title={el.title} subtitle={el.subtitle} authors={el.Authors} layout={el.layout}></Book>
                    })}
                </div>
            </div>
        </div>
    )
}


export default Books

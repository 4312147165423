import React, {useEffect, useRef} from "react";
import {Link} from "gatsby";
import {headlineAnimation} from "../Animation/animation";
import parseMarkdown from "../utils/parseMarkdown";

const Book = ({index, rand, slug, title, subtitle, authors, layout}) => {
    let pos;
    switch(layout){
        case "left": pos = "start";break;
        case "right": pos = "end";break;
        case "leftcenter": pos = "second";break;
        case "rightcenter": pos = "third";break;
    }


    return (
        <div className={"book__outer " + pos} >
            <Link to={"/" + slug} className="book__inner hover-arrow hover-moveup ">
                <p className="minion authors">
                    {authors.map((el, key) => {
                        return <span>{el.name}{key === authors.length-1 ? '' : ', '}</span>
                    })}
                </p>
                <div className="separator short arrow"></div>
                <h2 className="museo center" dangerouslySetInnerHTML={{ __html: parseMarkdown(title) }}></h2>

                <p className="museo center">{subtitle}</p>
            </Link>
        </div>
    )
}


export default Book
